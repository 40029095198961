<template>
  <div class="auction-house-checkin">
    <div class="step-auction">
      <el-steps :active="active">
        <el-step title="注册信息"></el-step>
        <el-step title="基本信息"></el-step>
        <el-step title="提交成功"></el-step>
      </el-steps>
    </div>
    <div class="auction-from" v-if="marking === 1">
      <el-form :model="auctionHouseRegister" :rules="rules" ref="auctionHouseRegister" label-width="170px"
        class="demo-ruleForm">
        <el-form-item label="用户名" prop="userName">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.userName" :disabled='BackShow' placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email" :rules="[
      { required: true, message: '请输入邮箱地址', trigger: 'blur' },
      { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }]">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.email" :disabled='BackShow' placeholder="请输入邮箱地址"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="passwordOk">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.passwordOk" type="password" :disabled='BackShow'
            autocomplete="off" placeholder="密码要求为8-16位数字、英文字母、特殊符号中至少两种类型以上的组合">
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.password" type="password" :disabled='BackShow' autocomplete="off"
            placeholder="请保持与上次密码输入一致"></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.contacts" :disabled='BackShow' placeholder="请填写您的真实姓名">
          </el-input>
        </el-form-item>
        <el-form-item label="联系人电话" prop="phone">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.phone" :disabled='BackShow' placeholder="请输入手机号">
          </el-input>
        </el-form-item>
        <div class="terms">
          <input type="checkbox" v-model="checked" checked>
          <p>我已阅读并同意<a href="javascript:;">《用户服务协议条款》</a>及对拍卖商进行约束的条款，开通焱十一同步拍卖会的创建资格。</p>
        </div>
      </el-form>
      <div class="button-okz">
        <button class="go-one" @click="next('auctionHouseRegister')">下一步</button>
      </div>
    </div>
    <div class="auction-from" v-if="marking === 2">
      <el-form :model="auctionHouseRegister" :rules="rules" ref="auctionHouseRegister" label-width="190px"
        class="demo-ruleForm">
        <el-form-item label="LOGO" prop="logo">
          <div class="upload-img logo-box">
            <el-upload action="" list-type="picture-card" multiple :limit="1" :on-exceed="handleExceed"
              :on-remove="removeChange" :http-request='httpRequest' :on-change="getFile" :file-list="fileListLogo"
              :class="{hide:hideUploadEditFour}">
              <i class="el-icon-plus">
              </i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <span>您上传的Logo大小不超过3MB</span>
          </div>
        </el-form-item>
        <el-form-item label="公司名称" prop="corporate_name">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.corporate_name" placeholder="请输入公司名称">
          </el-input>
        </el-form-item>
        <el-form-item label="公司税号" prop="company_tax_number">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.company_tax_number" placeholder="请输入您的公司税号"></el-input>
        </el-form-item>
        <el-form-item label="所属国家" prop="country">
          <div class="el-dis">
            <div class="el-select-box">
              <el-select v-model="auctionHouseRegister.country" @change="changeOne" placeholder="请选择">
                <el-option v-for="item in auctionAreaListData" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="auctionHouseRegister.countryTwo" @change="changeTwo" placeholder="请选择">
                <el-option v-for="item in auctionAreaListDataTwo" :key="item.code" :label="item.name"
                  :value="{value:item.code,label:item.name}">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="auctionHouseRegister.countryShtree" @change="changeShtree" placeholder="请选择">
                <el-option v-for="item in auctionAreaListSthreeData" :key="item.code" :label="item.name"
                  :value="{value:item.code,label:item.name}">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailed_address">
          <el-input type="textarea" v-model="auctionHouseRegister.detailed_address" placeholder="请填写您的详细地址">
          </el-input>
        </el-form-item>
        <el-form-item label="法人姓名" prop="name_of_legal_person">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.name_of_legal_person" placeholder="请输入您公司的法人姓名"></el-input>
        </el-form-item>
        <el-form-item label="证件类型" prop="certificates_type">
          <el-select v-model="auctionHouseRegister.certificates_type" @change="certificatesTypeIf"
            placeholder="法人身份证/驾驶证/护照">
            <el-option v-for="item in certificatesType" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <!-- prop="certificate_no" -->
        <el-form-item label="法人证件号">
          <el-input @keydown.native="keydown($event)" v-model="auctionHouseRegister.certificate_no"
            placeholder="请输入法人证件号码"></el-input>
        </el-form-item>
        <el-form-item label="法人证件照片" prop="certificate_photo">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" multiple
              :limit="limitOne" :on-exceed="handleExceedTwo" :before-upload='beforeUpload' :on-remove="handleRemove"
              :http-request='httpRequest' :on-change="getFileOne" :class="{hide:hideUploadEditTwo}"
              :file-list="fileListOne" accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <span>请上传法人证件照片</span>
          </div>

        </el-form-item>
        <el-form-item label="公司营业执照" prop="business_license">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" multiple :limit="2"
              :on-exceed="handleExceed" :http-request='httpRequest' :on-remove="handleRemoveOne"
              :class="{hide:hideUploadEdit}" :on-change="getFileShtree" :file-list="fileListTwo"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <span>请上传公司营业执照</span>
          </div>
        </el-form-item>
        <el-form-item label="资质证明" prop="qualification_certificate">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" multiple
              :on-exceed="handleExceed" :http-request='httpRequest' :on-remove="handleRemoveTwo"
              :on-change="getFileFour" :file-list="fileList" :class="{hide:hideUploadEditStree}"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .JPG, .JPEG, .PBG, .GIF, .BMP">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <span>请上传资质证明
              <span style="color:#999">(比如：拍卖许可证)</span>
            </span>
          </div>
        </el-form-item>
      </el-form>
      <div class="button-okz">
        <button class="go-one" @click="submit('auctionHouseRegister')">提交注册</button>
        <button class="go-two" @click="nextBack()">返回上一步</button>
      </div>

    </div>
    <div class="auction-success" v-if="marking === 3 &&noSuccess">
      <img class="s-b-as" src="../../assets/images/tongguo.png" alt="">
      <p>您的信息已提交成功，我们将在1-3个工作日内审核，请您耐心等待。</p>
    </div>
    <div class="auction-success" v-if="noSuccessS">
      <img class="s-b-as" src="../../assets/images/shz@2x.png" alt="">
      <p>您的信息已提交成功，我们将在1-3个工作日内进行审核，请您耐心等待～</p>
    </div>
    <div class="auction-success" v-if="noSuccessTwo">
      <img class="s-b-as" src="../../assets/images/shibai.png" alt="">
      <p>您提交的信息未通过审核，您可以<a href="javascript:;" class="s-b-col" @click="again()">再次提交</a>或<a class="s-b-col"
          href="javascript:;">联系客服</a>
      </p>
    </div>
  </div>
</template>
<script>
  import {
    auctionHouseRegisterApi,
    uploadFile,
    auctionAreaList,
    getAuctionHouseRegisterDesc
  } from '../../api/index'
  import OSS from 'ali-oss'
  export default {
    name: 'Auctionhousecheckin',
    data() {
      var testPassword = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
      var validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入密码'))
        } else if (!testPassword.test(this.auctionHouseRegister.passwordOk)) {
          callback(new Error('密码格式有误，请重新输入'))
        } else {
          if (this.auctionHouseRegister.password !== '') {
            this.$refs.auctionHouseRegister.validateField('password')
          }
          callback()
        }
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.auctionHouseRegister.passwordOk) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        active: 1,
        auctionHouseRegister: {
          userName: '',
          email: '',
          password: '',
          passwordOk: '',
          contacts: '',
          phone: '',
          logo: '',
          corporate_name: '',
          company_tax_number: '',
          country: '',
          countryTwo: '',
          countryShtree: '',
          location: '',
          detailed_address: '',
          website: '',
          bank_of_deposit: '',
          collection_account_number: '',
          introduction: '',
          terms: '',
          name_of_legal_person: '',
          certificates_type: '',
          certificate_no: '',
          certificate_photo: [],
          business_license: [],
          qualification_certificate: '',
          id: localStorage.getItem('statusLogon') === '3' ? Number(localStorage.getItem('loginId')) : 0

        },
        limitOne: 0,
        marking: 1,
        checked: true,
        selectShow: true,
        certificatesType: [{
            id: 1,
            name: '法人身份证'
          },
          {
            id: 2,
            name: '驾驶证'
          },

          {
            id: 3,
            name: '护照'
          }
        ],
        value: '',
        rules: {
          userName: [{
            required: true,
            message: '请输入用户名',
            trigger: 'change'
          }],
          passwordOk: [{
              required: true,
              validator: validatePass,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }
          ],
          password: [{
              required: true,
              validator: validatePass2,
              trigger: 'change'
            },
            {
              min: 8,
              max: 16,
              message: '密码长度不正确',
              trigger: 'change'
            }

          ],
          contacts: [{
            required: true,
            message: '请输入联系人',
            trigger: 'change'
          }],
          country: [{
            required: true,
            message: '请选择所属国家',
            trigger: 'change'
          }],
          logo: [{
            required: true,
            message: '请上传logo',
            trigger: 'change'
          }],
          phone: [{
              required: true,
              message: '请输入联系人电话',
              trigger: 'change'
            }
            // {
            //   required: true,
            //   pattern: '^[1][3,4,5,6,7,8,9][0-9]{9}$',
            //   message: '请输入正确的电话号码',
            //   trigger: ['blur', 'change']
            // }
          ],
          corporate_name: [{
              required: true,
              message: '请填写公司名称',
              trigger: 'change'
            },
            {
              min: 0,
              max: 100,
              message: '不得超过100个字符',
              trigger: 'change'
            }
          ],
          company_tax_number: [{
            required: true,
            message: '请填写公司税号',
            trigger: 'change'
          }],
          detailed_address: [{
            required: true,
            message: '请填写详细地址',
            trigger: 'change'
          }],
          name_of_legal_person: [{
            required: true,
            message: '请填写法人姓名',
            trigger: 'change'
          }],
          // certificate_no: [
          //   { required: true, message: '请填写法人证件号码', trigger: 'change' },
          //   { min: 18, max: 18, message: '请输入18位身份证号码', trigger: 'change' }
          // ],
          certificate_photo: [{
            required: true,
            message: '请上传法人证件照片',
            trigger: 'change'
          }],
          business_license: [{
            type: 'array',
            required: true,
            message: '请上传公司营业执照',
            trigger: 'change'
          }],
          qualification_certificate: [{
            required: true,
            message: '请上传资质证明',
            trigger: 'change'
          }],
          certificates_type: [{
            required: true,
            message: '请选择证件类型',
            trigger: 'change'
          }]
        },
        fileList: [],
        fileListOne: [],
        fileListTwo: [],
        dialogImageUrl: '',
        dialogVisible: false,
        ossToken: {},
        auctionAreaListData: [],
        auctionAreaListDataTwo: [],
        auctionAreaListSthreeData: [],
        countyTxt: '',
        countyTxtStree: '',
        countyTxtTwo: '',
        countyTxtCode: '',
        imgData: [],
        qualificationImg: [],
        hideUploadEdit: false,
        hideUploadEditTwo: false,
        hideUploadEditStree: false,
        hideUploadEditFour: false,
        noSuccess: true,
        noSuccessTwo: false,
        noSuccessS: false,
        getAuctionHouseRegisterDescData: {},
        fileListLogo: [],
        BackShow: false
      }
    },
    created() {
      if (Number(localStorage.getItem('statusLogon')) === 1) {
        this.marking = 3
        this.active = 3
        this.noSuccessS = true
        this.noSuccess = false
      } else if (Number(localStorage.getItem('statusLogon')) === 3) {
        this.marking = 3
        this.active = 3
        this.noSuccessTwo = true
        this.noSuccess = false
      }
    },
    destroyed() {
      localStorage.removeItem('statusLogon')
      localStorage.removeItem('goBackData')
    },
    watch: {
      countyTxtTwo: {
        handler(newV, oldV) {
          if (newV !== oldV) {
            this.auctionHouseRegister.countryShtree = ''
          }
        }
      }
    },

    methods: {
      // 禁止输入空格
      keydown(e) {
        if (e.keyCode == 32) {
          e.returnValue = false
        }
      },
      next(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.checked === false) {
              this.$message.error('请勾选上方协议')
              return
            }
            // this.auctionHouseRegister.passwordOk = this.$md5(this.auctionHouseRegister.passwordOk)
            // this.auctionHouseRegister.password = this.$md5(this.auctionHouseRegister.password)
            this.auctionAreaList()
            if (this.active++ > 2) this.active = 0
            this.marking = 2
            if (localStorage.getItem('statusLogon') && !localStorage.getItem('goBackData')) {
              this.getAuctionHouseRegisterDesc()
            }
            if (localStorage.getItem('goBackData') && !localStorage.getItem('statusLogon')) {
              const params = JSON.parse(localStorage.getItem('goBackData'))
              this.fileListLogo = []
              if (params.logo !== '') {
                this.fileListLogo.push({
                  name: 'logo',
                  url: params.logo
                })
              }
              // 营业执照
              this.fileListTwo = []
              if (params.business_license.length > 0) {
                params.business_license.forEach(item => {
                  this.fileListTwo.push({
                    url: item
                  })
                })
              }
              this.fileListOne = []
              if (params.certificate_photo !== '') {
                if (params.certificates_type === 1) {
                  const photo = params.certificate_photo.split(',')
                  if (photo.length === 2) {
                    this.fileListOne.push({
                      name: 'image1',
                      url: photo[0]
                    }, {
                      name: 'image2',
                      url: photo[1]
                    })
                  } else {
                    this.fileListOne.push({
                      name: 'image1',
                      url: photo[0]
                    })
                  }
                } else if (params.certificates_type === 2 || params.certificates_type === 3) {
                  this.fileListOne.push({
                    name: 'image',
                    url: params.certificate_photo
                  })
                }
                this.getFileOneShow()
              } else {
                this.fileListOne = []
                this.hideUploadEditTwo = false
                this.imgData = []
              }

              this.fileList = []
              if (params.qualification_certificate !== '') {
                const qualificationCertificate = params.qualification_certificate.split(',')
                for (let index = 0; index < qualificationCertificate.length; index++) {
                  this.fileList.push({
                    name: 'image' + index,
                    url: qualificationCertificate[index]
                  })
                }
              }
              if (this.fileList.length >= 6) {
                this.hideUploadEditStree = true
              }
            }
            // this.getFileOneShow()
          } else {
            return false
          }
        })
      },
      // 再次提交数据初始化
      async getAuctionHouseRegisterDesc() {
        const res = await getAuctionHouseRegisterDesc({
          authorization: localStorage.getItem('loginToken'),
          userId: Number(localStorage.getItem('loginId')),
          x_user_id: Number(localStorage.getItem('loginId'))
        })

        if (res.code === 0) {
          this.auctionHouseRegister.userName = res.data.userName
          this.auctionHouseRegister.email = res.data.email
          this.auctionHouseRegister.contacts = res.data.contacts
          this.auctionHouseRegister.phone = res.data.phone
          this.auctionHouseRegister.logo = res.data.logo
          this.fileListLogo = []
          if (this.auctionHouseRegister.logo !== '') {
            this.fileListLogo.push({
              name: 'logo',
              url: this.auctionHouseRegister.logo
            })
          }
          this.logoShow()
          this.auctionHouseRegister.corporate_name = res.data.corporateName
          this.auctionHouseRegister.company_tax_number = res.data.companyTaxNumber
          this.auctionHouseRegister.country = res.data.country
          this.auctionHouseRegister.detailed_address = res.data.detailedAddress
          this.auctionHouseRegister.name_of_legal_person = res.data.nameOfLegalPerson
          this.auctionHouseRegister.certificates_type = res.data.certificatesType
          this.auctionHouseRegister.certificate_no = res.data.certificateNo
          this.auctionHouseRegister.certificate_photo = res.data.certificatePhoto // 法人证件照
          this.auctionHouseRegister.qualification_certificate = res.data.qualificationCertificate // 资质证明
          this.auctionHouseRegister.passwordOk = res.data.password
          this.auctionHouseRegister.password = res.data.password
          if (this.auctionHouseRegister.country !== 0) {
            this.selectShow = false
          } else {
            this.selectShow = true
          }
          const city = res.data.location.split(',')
          this.auctionHouseRegister.countryTwo = city[0]
          this.auctionHouseRegister.countryShtree = city[1]
          if (res.data.businessLicense.split(',').length == 2) {
            this.auctionHouseRegister.business_license = res.data.businessLicense.split(',')
          } else {
            this.auctionHouseRegister.business_license = [res.data.businessLicense]
          }
          // 营业执照
          this.fileListTwo = []
          if (this.auctionHouseRegister.business_license.length > 0) {
            this.auctionHouseRegister.business_license.forEach(item => {
              this.fileListTwo.push({
                url: item
              })
            })
          }
          // if (this.auctionHouseRegister.business_license !== '') {
          //   this.fileListTwo.push({
          //     name: 'businessLicense',
          //     url: this.auctionHouseRegister.business_license
          //   })
          // }

          this.getFileShtreeT()
          this.fileListOne = []
          if (this.auctionHouseRegister.certificates_type === 1) {
            const photo = this.auctionHouseRegister.certificate_photo.split(',')
            this.fileListOne.push({
              name: 'image1',
              url: photo[0]
            }, {
              name: 'image2',
              url: photo[1]
            })
          } else if (this.auctionHouseRegister.certificates_type === 2 || this.auctionHouseRegister
            .certificates_type === 3) {
            this.fileListOne.push({
              name: 'image',
              url: this.auctionHouseRegister.certificate_photo
            })
          }
          // this.imgData = this.fileListOne
          this.getFileOneShow()
          this.fileList = []
          if (res.data.qualificationCertificate !== '') {
            const qualificationCertificate = res.data.qualificationCertificate.split(',')
            for (let index = 0; index < qualificationCertificate.length; index++) {
              this.fileList.push({
                name: 'image' + index,
                url: qualificationCertificate[index]
              })
            }
          }
          if (this.fileList.length >= 6) {
            this.hideUploadEditStree = true
          }
          // this.qualificationImg = this.fileList
          if (res.data.country === 0) {
            this.auctionAreaListTwo(res.data.country)
          }
        } else {
          this.$message.error(res.msg)
        }
      },
      // 提交注册
      async submit(formName) {
        if (this.auctionHouseRegister.certificates_type === 1) {
          if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
            if (this.fileListOne.length !== 2) {
              this.$message.error('法人身份证必须上传2张')
              return
            }
          } else {
            if (this.imgData.length !== 2) {
              this.$message.error('法人身份证必须上传2张')
              return
            }
          }
        }
        const fig = []
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          this.fileListOne.forEach(item => {
            fig.push(item.url)
          })
          this.auctionHouseRegister.certificate_photo = fig.toString()
        } else {
          this.imgData.forEach(item => {
            fig.push(item.name)
          })
        }
        this.auctionHouseRegister.certificate_photo = fig.toString()
        const qualificationData = []
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          this.fileList.forEach(item => {
            qualificationData.push(item.url)
          })
        } else {
          this.qualificationImg.forEach(item => {
            qualificationData.push(item.name)
          })
        }
        this.auctionHouseRegister.qualification_certificate = qualificationData.toString()
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const obj = JSON.parse(JSON.stringify(this.auctionHouseRegister))
            obj.business_license = obj.business_license.toString()
            if (obj.passwordOk.length < 17) { //再次提交的时候无需再加密
              obj.passwordOk = this.$md5(obj.passwordOk)
              obj.password = this.$md5(obj.password)
            }
            const res = await auctionHouseRegisterApi(obj)
            if (res.code === 0) {
              if (this.active++ > 2) this.active = 0
              this.marking = 3
              this.noSuccess = true
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false
          }
        })
      },
      handleRemove(file, fileList) {
        this.hideUploadEditTwo = false
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          for (let index = 0; index < this.fileListOne.length; index++) {
            if (file.uid === this.fileListOne[index].uid) {
              this.fileListOne.splice(index, 1)
            }
          }
        } else {
          for (let index = 0; index < this.imgData.length; index++) {
            if (file.uid === this.imgData[index].uid) {
              this.imgData.splice(index, 1)
            }
          }
        }
      },
      handleRemoveTwo(file, fileList) {
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          for (let index = 0; index < this.fileList.length; index++) {
            if (file.uid === this.fileList[index].uid) {
              this.fileList.splice(index, 1)
            }
          }
        } else {
          for (let index = 0; index < this.qualificationImg.length; index++) {
            if (file.uid === this.qualificationImg[index].uid) {
              this.qualificationImg.splice(index, 1)
            }
          }
        }

        if (this.qualificationImg.length <= 6) {
          this.hideUploadEditStree = false
        }
        this.$refs.auctionHouseRegister.validateField('qualification_certificate')
      },
      handleRemoveOne(file, fileList) {
        //this.fileListTwo = []
        //this.auctionHouseRegister.business_license = ''
        const indexDel = this.auctionHouseRegister.business_license.findIndex(item => item == file.url)
        this.auctionHouseRegister.business_license.splice(indexDel, 1);
        if (this.auctionHouseRegister.business_license.length < 2) {
          this.hideUploadEdit = false
        }
      },
      // 获取所属国家
      async auctionAreaList() {
        const res = await auctionAreaList({
          code: ''
        })
        if (res.code === 0) {
          this.auctionAreaListData = res.data.newList
        } else {
          this.$message.error(res.msg)
        }
      },
      async auctionAreaListTwo(id) {
        if (id === 0) {
          id = '000'
        }
        const res = await auctionAreaList({
          code: id
        })
        if (res.code === 0) {
          this.auctionAreaListDataTwo = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      async auctionAreaListSthree(id) {
        const res = await auctionAreaList({
          code: id.toString()
        })
        if (res.code === 0) {
          this.auctionAreaListSthreeData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 选择第一个国家
      changeOne(params) {
        const {
          value,
          label
        } = params
        this.countyTxtCode = value
        if (params !== 0) {
          this.selectShow = false
        } else {
          this.selectShow = true
          this.auctionAreaListTwo(params)
          this.countyTxt = label
        }
      },
      changeTwo(params) {
        const {
          value,
          label
        } = params
        if (this.selectShow === true) {
          this.auctionAreaListSthree(value)
          this.countyTxtTwo = label
        }
      },
      changeShtree(params) {
        const {
          value,
          label
        } = params
        this.countyTxtStree = label
        this.auctionHouseRegister.location = this.countyTxtTwo + ',' + this.countyTxtStree
      },
      // 返回上一步
      nextBack() {
        const qualificationDataO = []
        const fig = []
        localStorage.setItem('errorData', true)
        if (localStorage.getItem('goBackData') || localStorage.getItem('errorData')) {
          this.fileList.forEach(item => {
            qualificationDataO.push(item.url)
          })
          this.fileListOne.forEach(item => {
            fig.push(item.url)
          })
        } else {
          this.qualificationImg.forEach(item => {
            qualificationDataO.push(item.name)
          })
          this.imgData.forEach(item => {
            fig.push(item.name)
          })
        }
        this.auctionHouseRegister.certificate_photo = fig.toString()
        this.auctionHouseRegister.qualification_certificate = qualificationDataO.toString()
        localStorage.setItem('goBackData', JSON.stringify(this.auctionHouseRegister))

        // this.$refs.auctionHouseRegister.resetFields()
        if (this.active-- > 2) this.active = 0
        this.marking = 1
        if (localStorage.getItem('statusLogon') === '3') {
          this.BackShow = true
        }
      },
      handlePreview(file) {},
      handleExceed(files, fileList) {
        this.$message.warning('当前限制上传 1 个文件')
      },
      handleExceedTwo(files, fileList) {
        this.$message.warning(`当前限制上传 ${this.limitOne} 个文件`)
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },

      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      httpRequest() {

      },
      // 上传logo
      // 上传oss
      async getFile(event) {
        const isLt300K = event.raw.size / 1024 / 1024 < 3
        const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(event.raw.type)
        if (!isLt300K) {
          this.$message.error('上传图片大小不能超过3M!')
          this.fileListLogo = []
          return
        }
        if (!isTypeTrue) {
          this.$message.error('上传图片格式不对!')
          this.fileListLogo = []
          return
        }

        new Promise(function(resolve, reject) {
          //upload内部需要一个promise,简单的return出false并没有什么用
          let _URL = window.URL || window.webkitURL;
          let img = new Image();
          img.onload = function() {
            let valid = img.width == img.height;
            valid ? resolve() : reject();
          };
          img.src = _URL.createObjectURL(event.raw); //onload是异步加载
        }).then(
          async () => {
              var uploadHost = 'https://pic.easyebid.com'
              await this.uploadFile()
              var client = new OSS({
                region: 'oss-cn-beijing',
                accessKeyId: this.ossToken.accessKeyId,
                accessKeySecret: this.ossToken.accessKeySecret,
                bucket: 'yideonline',
                stsToken: this.ossToken.securityToken
              })
              var file = event.raw
              const type = file.name.split('.')
              var timestamp = Date.parse(new Date())
              const len = type.length
              const fileName = 'yidelogo' + timestamp + '.' + type[len - 1]
              const result = await client.put(`logo/${fileName}`, file)
              this.auctionHouseRegister.logo = `${uploadHost}/${result.name}`
              this.$refs.auctionHouseRegister.validateField('logo')
              this.logoShow()
              return event.raw;
            },
            () => {
              this.$message.error("上传图片需宽高比例相等");
              this.fileListLogo = []
              return Promise.reject();
            }
        );
      },
      logoShow() {
        if (this.auctionHouseRegister.logo !== '') {
          this.hideUploadEditFour = true
        }
      },
      removeChange(file) {
        this.auctionHouseRegister.logo = ''
        this.$refs.auctionHouseRegister.validateField('logo')
        this.hideUploadEditFour = false
      },
      beforeUpload(file) {
        if (this.auctionHouseRegister.certificates_type === '') {
          this.$message.error('请先选择证件类型')
          this.fileListOne = []
          this.imgData = []
          this.hideUploadEditTwo = false
        }
      },
      // 上传法人证件照片
      async getFileOne(event) {
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })
        var file = event.raw
        const type = file.name.split('.')
        const len = type.length
        const fileName = this.uuid() + '.' + type[len - 1]
        const result = await client.put(`cer/${fileName}`, file)
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          this.fileListOne.push({
            url: `${uploadHost}/${result.name}`,
            uid: event.uid
          })
          const params = this.fileListOne
          this.auctionHouseRegister.certificate_photo = params.toString()
        } else {
          this.imgData.push({
            uid: event.uid,
            name: `${uploadHost}/${result.name}`

          })
          const params = this.imgData
          this.auctionHouseRegister.certificate_photo = params.toString()
        }

        this.$refs.auctionHouseRegister.validateField('certificate_photo')
        this.getFileOneShow()
      },
      getFileOneShow() {
        if (this.auctionHouseRegister.certificates_type !== '') {
          if (this.auctionHouseRegister.certificates_type === 1) {
            if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
              if (this.fileListOne.length >= 2) {
                this.hideUploadEditTwo = true
              }
            } else {
              if (this.imgData.length >= 2) {
                this.hideUploadEditTwo = true
              }
            }
          } else {
            if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
              if (this.fileListOne.length <= 1) {
                this.hideUploadEditTwo = true
              }
            } else {
              if (this.imgData.length <= 1) {
                this.hideUploadEditTwo = true
              }
            }
          }
        }
      },
      // 上传营业执照
      async getFileShtree(event) {
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })
        var file = event.raw
        const type = file.name.split('.')
        const len = type.length
        const fileName = this.uuid() + '.' + type[len - 1]
        const result = await client.put(`cer/${fileName}`, file)
        const imgUrl = `${uploadHost}/${result.name}`
        this.auctionHouseRegister.business_license.push(imgUrl)
        this.$refs.auctionHouseRegister.validateField('business_license')
        this.getFileShtreeT()
      },
      getFileShtreeT() {
        if (this.auctionHouseRegister.business_license.length > 1) {
          this.hideUploadEdit = true
        }
      },
      // 上传资质证明
      async getFileFour(event) {
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })
        var file = event.raw
        const type = file.name.split('.')
        const len = type.length
        const fileName = this.uuid() + '.' + type[len - 1]
        const result = await client.put(`cer/${fileName}`, file)
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          this.fileList.push({
            uid: event.uid,
            url: `${uploadHost}/${result.name}`
          })
          const params = this.fileList
          this.auctionHouseRegister.qualification_certificate = params.toString()
        } else {
          this.qualificationImg.push({
            uid: event.uid,
            name: `${uploadHost}/${result.name}`
          })
          const params = this.qualificationImg
          this.auctionHouseRegister.qualification_certificate = params.toString()
        }

        this.$refs.auctionHouseRegister.validateField('qualification_certificate')
        if (localStorage.getItem('statusLogon') === '3' || localStorage.getItem('goBackData')) {
          if (this.fileList.length >= 6) {
            this.hideUploadEditStree = true
          }
        } else {
          if (this.qualificationImg.length >= 6) {
            this.hideUploadEditStree = true
          }
        }
      },
      certificatesTypeIf(event) {
        if (event === 1) {
          this.limitOne = 2
        } else {
          this.limitOne = 1
        }
        this.hideUploadEditTwo = false
        this.imgData = []
        this.fileListOne = []
      },
      // 再次提交
      again() {
        if (localStorage.getItem('statusLogon') === '3') {
          this.getAuctionHouseRegisterDesc()
          this.auctionAreaList()
        }
        this.marking = 2
        this.active = 2
        this.noSuccess = true
        this.noSuccessTwo = false
      },
      uuid() {
        let s = [];
        const hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
      }
    }
  }
</script>
<style lang="less" scoped>
  .auction-house-checkin {
    /deep/ .el-textarea__inner {
      width: 600px;
      height: 100px;
      resize: none;
      outline: none;
      border: 1px solid #bfc3c4;
      padding-left: 13px;
    }

    /deep/ .hide .el-upload--picture-card {
      display: none;
    }

    .step-auction {
      // width: 408px;
      // margin: 0 auto;
      padding: 0 650px;
      margin: 20px 0;

      /deep/ .el-step.is-horizontal {
        flex-basis: 0% !important;
      }

      /deep/ .el-step__line-inner {
        color: #cda156;
      }

      /deep/ .el-step__head.is-finish {
        color: #cda156;
        border-color: #cda156;
      }

      // /deep/ .el-step__icon-inner {
      //   color: #cda156;
      // }
      // /deep/ .el-step__icon-inner {
      //   color: #cda156;
      // }
      /deep/ .el-step__icon.is-text {
        margin-right: 274px !important;
      }

      /deep/ .el-step__title.is-finish {
        color: #cda156;
      }

      /deep/ .el-step__main {
        width: 70px;
        margin-left: -18px;
      }
    }

    .auction-from {
      // padding: 0 500px 40px;
      padding-left: 500px;
      padding-bottom: 40px;
      margin: 0 auto;

      /deep/ .el-form-item {
        display: flex;
        align-items: center;
        // width: 730px;
      }

      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 120px;
        height: 80px;
      }

      .logo-box {
        /deep/.el-upload-list--picture-card .el-upload-list__item {
          width: 120px !important;
          height: 120px !important;
        }
      }

      .el-dis {
        display: flex;
        justify-content: space-between;
        width: 600px;

        .el-select-box {
          width: 195px;

          /deep/ .el-input__inner {
            width: 195px;
          }
        }
      }

      .select-box {
        width: 600px;
        display: flex;
        justify-content: space-between;

        .select {
          border-radius: 5px;
          width: 190px;
          height: 50px;
          background: #fff;
          color: #999;
          font-size: 16px;
          border: 1px solid #bfc3c4;
          display: inline-block;
          // margin-right: 10px;
          outline: none;
          padding-left: 13px;
        }
      }

      .textarea-box {
        width: 600px;

        .textarea {
          width: 100%;
          height: 100px;
          resize: none;
          outline: none;
          border: 1px solid #bfc3c4;
          padding-left: 13px;
        }
      }

      .el-button--primary {
        width: 120px;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        border: none;
      }

      .upload-img {
        display: flex;
        align-items: center;

        span {
          padding-left: 20px;
        }

        /deep/ .el-upload--picture-card {
          width: 120px;
          height: 80px;
          background: #fff;
          border: 1px solid #bfc3c4;
          line-height: 80px;
        }

        /deep/ .el-icon-plus {
          background: url("../../assets/images/shangchuantupian备份 2@2x.png") no-repeat;
          background-size: 27px;
        }

        /deep/ .el-upload--picture-card i {
          color: transparent;
        }
      }

      /deep/ .el-input__inner {
        height: 50px;
        border: 1px solid #bfc3c4;
        background: #ffffff;
        color: #333;
        font-size: 16px;
        width: 600px;
      }

      /deep/ .el-form-item__content {
        margin-left: 0 !important;
      }

      /deep/ .el-form-item__label {
        font-weight: 600;
        color: rgb(56, 55, 55);
        font-size: 16px;
      }

      .terms {
        text-align: left;
        padding-left: 170px;
        display: flex;
        align-items: center;

        input[type="checkbox"] {
          cursor: pointer;
          position: relative;
          width: 15px;
          height: 15px;
          font-size: 14px;
        }

        input[type="checkbox"]::after {
          position: absolute;
          top: 0;
          color: #000;
          width: 15px;
          height: 15px;
          display: inline-block;
          visibility: visible;
          padding-left: 0px;
          text-align: center;
          content: " ";
          border-radius: 3px;
        }

        input[type="checkbox"]:checked::after {
          content: "✓";
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          background-color: #cda156;
        }

        a {
          color: #cda156;
        }

        p {
          font-weight: 400;
          color: #333;
          font-size: 12px;
          margin-bottom: 0;
          margin-left: 6px;
        }
      }

      .button-okz {
        padding-left: 300px;
        display: flex;
        flex-direction: column;

        .go-one {
          width: 280px;
          color: #6e5121;
          font-size: 18px;
          opacity: 0.7;
          border-radius: 8px;
          background: #cda156;
          height: 44px;
          // text-align: center;
          line-height: 44px;
          border: none;
          margin-top: 40px;
        }

        .go-two {
          width: 280px;
          color: #cda156;
          font-size: 18px;
          opacity: 0.7;
          // border-radius: 8px;
          background: #fff;
          // height: 44px;
          // text-align: center;
          // line-height: 44px;
          border: none;
          margin-top: 20px;
        }
      }
    }

    .auction-success {
      margin-top: 80px;

      .s-b-as {
        width: 152px;
      }

      .s-b-col {
        color: #cda156;
        text-decoration: underline;
        font-size: 16px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        color: #777;
        margin: 20px 0 148px 0;
      }
    }
  }
</style>
